<template>
    <div class="om_collect_list_wrap">
        <ul class="item_card_wrap">
            <li class="list_item" v-for="(data,index) in itemData" :key="`item${index}`">
                <div class="thumbnail" :style="` background-image: url(${returnThumbnail(data)});`"
                     @click="moveDetail(data.i_idx)">
                    <div class="card_category cc_category">{{ data.ic_name_eng }}</div>
                    <div class="card_category cc_real" v-if="data.i_type ==='real'">{{ $t('real') }}</div>
                </div>
                <div class="card_detail">
                    <div class="profile_group">
                        <el-popover
                                placement="bottom"
                                width="200"
                                @show="getMemberData(data.provider)"
                                :open-delay="500"
                                trigger="click">
                            <div v-if="memberCheck">
                                <!--회원 팝업 디자인 필요-->
                                <img class="card_profile" :src="returnMemberImg()"
                                     style="width: 100px;height: 100px;">
                            </div>
                            <img slot="reference" class="card_profile" :src="returnItemProfile(data,'provider')"
                                 alt="">
                        </el-popover>

                        <el-popover
                                placement="bottom"
                                width="200"
                                @show="getMemberData(data.im_originator)"
                                :open-delay="500"
                                trigger="click">
                            <div>
                                <div v-if="memberCheck">
                                    <!--회원 팝업 디자인 필요-->
                                    <img class="card_profile" :src="returnMemberImg()"
                                         style="width: 100px;height: 100px;">
                                </div>
                            </div>
                            <img slot="reference" class="card_profile" :src="returnItemProfile(data,'originator')"
                                 alt="">
                        </el-popover>
                        <el-popover
                                placement="bottom"
                                width="200"
                                @show="getMemberData(data.ibc_curator)"
                                :open-delay="500"
                                trigger="click">
                            <div>
                                <div v-if="memberCheck">
                                    <!--회원 팝업 디자인 필요-->
                                    <img class="card_profile" :src="returnMemberImg()"
                                         style="width: 100px;height: 100px;">
                                </div>
                            </div>
                            <img class="card_profile" slot="reference" v-if="checkCurator(data)"
                                 :src="returnItemProfile(data,'curator')"
                                 alt="">
                        </el-popover>
                    </div>
                    <div class="icon_group" @click="moveDetail(data.i_idx)">
                        <div class="icon_wrap pr-10">
                            <img class="icon heart" src="@/assets/image/main/heart1.png" alt="">
                            <div class="icon_count">{{ data.im_like }}</div>
                        </div>
                        <div class="icon_wrap">
                            <img class="icon eye" src="@/assets/image/main/eye1.png" alt="">
                            <div class="icon_count">{{ data.im_real_hit }}</div>
                        </div>
                    </div>
                    <div class="card_title" @click="moveDetail(data.i_idx)">{{ data.i_name }}</div>
                    <div class="premium" v-if="checkPremium(data)"  @click="moveDetail(data.i_idx)">Premium</div>
                    <div class="item_status time" @click="moveDetail(data.i_idx)">{{ returnItemType(data) }}</div>
                    <div class="item_price" @click="moveDetail(data.i_idx)">
                        <img class="item_small_logo" src="@/assets/image/main/small_logo.png" alt="">
                        <div class="price">{{ returnCommas(data.i_price) }} Mg</div>
                    </div>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
import imageOption from "@/mixins/imageOption";
import dateMixins from "@/mixins/dateMixins";
import util from "@/mixins/util";
import {mapState} from "vuex";
import imageResizeMixins from "@/mixins/imageResizeMixins";
import EventBus from "@/utils/event-bus";

let timeInterval = null;
let getItemInterval = null;
export default {
    name: "ItemListDefaultLayout",
    mixins: [imageOption, dateMixins],
    components: {},
    inject: [],
    provide() {
        return {}
    },
    props: {
        itemData: {},
    },
    data() {
        return {
            memberCheck: false,
            currentTime: '',
            memberData: {},

            isGetData: false,
        }
    },
    beforeRouterEnter() {
    },
    created() {
        this.getCurrentTime();
    },
    mounted() {
        timeInterval = setInterval(this.getCurrentTime, 1000);
        getItemInterval = setInterval(() => {
            EventBus.$emit('getItemList')
        }, 1000 * 60)
    },
    beforeDestroy() {
        clearInterval(timeInterval)
        clearInterval(getItemInterval)
    },
    destroyed() {
    },
    computed: {
        ...mapState({
            itemCategoryList: state => state.itemCategory.itemCategoryList,
        })
    },
    methods: {
      returnCommas(x = 0) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
        moveDetail(data) {
            this.$router.push(`/premiummarket/${data}`)
        },
        returnThumbnail(data) {
            if (!util.isEmpty(data.ItemFile) && !util.isEmpty(data.ItemFile.Item)) {
                // return `${data.ItemFile.Item[0].org_url}?${this.setImageOptions(278, null, null)}`;
                return this.setFitWidthImg(data.ItemFile.Item, 278);
            }
        },
        returnItemProfile(data, type) {
            if (!util.isEmpty(data.profile) && !util.isEmpty(data.profile[type])) {
                return `${data.profile[type][0].org_url}?${this.setImageOptions(40, 40, 'png')}`;
            }
        },
        returnMemberImg() {
            return `${this.memberData.profile}?${this.setImageOptions(100, 100, 'jpg')}`
        },

        checkCurator(data) {
            if (!util.isEmpty(data.profile) && !util.isEmpty(data.profile.curator)) {
                return true
            }
            return false;
        },

        getMemberData(data) {
            this.memberData = {}
            this.memberData.profile = this.itemData[0].profile.provider[0].org_url;
            this.memberCheck = true;
            this.$forceUpdate()
        },
        returnItemType(data) {
            let currentTime = this.$moment().format('YYYY-MM-DD HH:mm:ss');
            if (util.isEmpty(data.ia_end_date)) {
                return '';
            }
            let diffTime = this.getDifferenceTime(data.ia_end_date, currentTime);
            return diffTime;
        },
        getCurrentTime() {
            this.currentTime = this.$moment().format('YYYY-MM-DD HH:mm:ss');
        },
        checkPremium(data) {
            if (data.im_status === 2 || data.im_status === 5 || data.im_status === 8) {
                return true
            }
            return false;
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
